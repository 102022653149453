import { notification } from 'antd';
import { UPDATE_FORM_ERRORS, UPDATE_CURRENT_STEP } from '../store/actions/form';
import { stepsFields } from '../store/reducers';

const paymentGatewayCommonErrors = {
  "Your transaction was not approved. CVV2 MISMATCH - CSC Value supplied is invalid": "security_code",
  "BLOCKED - This card number has been blocked to prevent potential fraud.": "card_number",
  "Your transaction was not approved.     DECLINE - Insufficient funds": "card_number",
  "Please provide a valid Credit Card Number.": "card_number"
};



const getFieldStepIndex = fieldName => {
  return stepsFields.filter(step=> step.includes(fieldName)).map(step=>stepsFields.indexOf(step))[0]
}

const markFieldsInvalid = (validationErrors, setError, dispatch) => {
  let stepToJumbTo = [];
  let validationErrorsArr = Object.entries(validationErrors).map(([key, value]) => {
    return { 'Field': key, 'Message': value[0] } 
  })
  const rhfErrors = validationErrorsArr.map(error => ({type: 'required', name: error.Field, message: error.Message}));
  const errorsObj = {}

  for (let i = 0; i < rhfErrors.length; i++) {
    errorsObj[rhfErrors[i].name] = rhfErrors[i]
    let fieldStep = getFieldStepIndex(rhfErrors[i].name)
    if (stepToJumbTo.length) {
        if (fieldStep < stepToJumbTo[0]) {
          stepToJumbTo[0] = fieldStep;
        } 
    } else {
      stepToJumbTo[0] = fieldStep;
    }
  }
  

  dispatch({type: UPDATE_FORM_ERRORS, values: errorsObj})
  dispatch({type: UPDATE_CURRENT_STEP, value: stepToJumbTo[0] + 1})
  rhfErrors.forEach((rhfError) => {
      const { name, type, message } = rhfError;
      setError(name, { type, message})
    }
  )
};

const errorHandler = ({ errors, validation }, setError, dispatch) => {

  // Our API Validations
  if (errors[0]?.code === 4) {

    markFieldsInvalid(validation, setError, dispatch);

  } else 
  
  // Payment Gateway Errors
  if (errors[0]?.code === 14) {
    let isCommonPaymentError = Object.keys(paymentGatewayCommonErrors).find(error => {
      return validation[0] === error
    })
    if ( isCommonPaymentError ) {
      let fieldName = paymentGatewayCommonErrors[validation[0]];
      // let errors = [{Field: fieldName, Message: validation[0]}]
      let validationObj = {
        [fieldName]: [validation[0]]
      }
      markFieldsInvalid({...validationObj}, setError, dispatch);

    } else {
      let paymentFields = stepsFields[2];
      // let paymentFieldsErrors = paymentFields.map(fieldName => ({Field: fieldName, Message: 'Invalid Input'}));
      let validationObj = {}
      paymentFields.forEach(fieldName => {
        validationObj[fieldName] = ['Invalid Input']
      })
      markFieldsInvalid(validationObj, setError, dispatch);
    }
    
  } else {
    notification.error({
      message: 'Error',
      description: errors[0]?.Message || 'Something went wrong, Try again later.',
      placement: 'topRight',
      duration: 10
    })
  }
  
}

export default errorHandler;