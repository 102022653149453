import { Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";

const RadioCheckboxField = ({
  control,
  name,
  type,
  options,
  optionsValues,
  rules,
  errorMsgs,
  controlId,
  label,
  setValue,
  defaultValue,
  checkedDisabled,
  minRequired,
  handleChange
}) => {
  let checkboxNextValue;
  return (
    <Controller 
      render={({ 
        field,
        fieldState: { invalid, isTouched, isDirty, error } 
      })=> {
        // eslint-disable-next-line no-unused-vars
        const { value: fieldValue, fieldRest } = field
        return (
        <Form.Group controlId={controlId}>
          {label ?
            <Form.Label>{label} {rules ? '*' : null}</Form.Label>
          : <span style={{height:'26px', display: 'inline-block'}} />
          }
          <InputGroup>
            {options.map((option, optionIndex) => (
              <div key={`inline-${option}`}>
                <Form.Check
                  {...fieldRest}
                  custom
                  type={type}
                  inline 
                  label={option}
                  name={name}
                  id={`${option}-${name}`}
                  // onChange={(e)=> field.onChange(e.target.value)}
                  onChange={(e)=> {
                    if ( (field?.value?.includes(option)) || checkedDisabled?.includes(option) ) {
                      if(minRequired && field.value.length === 1 && field.value[0] === option) return
                      
                      checkboxNextValue = field.value.filter(
                        value => value !== option
                      )
                      setValue(name, checkboxNextValue)
                    } else {
                      checkboxNextValue = field?.value?.concat(option)
                      setValue(name, checkboxNextValue)
                    }
                    handleChange(checkboxNextValue)
                
                  }}
                  isValid={isTouched && isDirty && !invalid}
                  isInvalid={invalid}
                  checked={([...checkedDisabled, ...defaultValue]?.includes(option)) || field?.value?.includes(option)}
                  disabled={checkedDisabled && checkedDisabled?.includes(option)}
                  value={defaultValue}
                  defaultValue={defaultValue}
                />
              </div>
            ))}
            
            <Form.Control.Feedback type="invalid">
              {error ?
                errorMsgs && error.type !== 'required' ?
                  errorMsgs[error.type] 
                :
                error.message ?
                  error.message :
                  'Required'
              : 
              null}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      )}}
      name={name}
      control={control}
      rules={rules}
    />
    
  )
}

export default RadioCheckboxField;