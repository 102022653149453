import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import StepsHeader from './StepsHeader'
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';


const WizardForm = ({ currentStep }) => {
  
  const steps = ['Requestor Info', 'Applicant Info', 'Payment'];
  return (
    <div>
      <Row className="text-center">
        <h1 className="form-title prime-color mt-4">Request a Mobile Fingerprinting Service</h1>
      </Row>

      <Row className="box-wrapper mt-4 mb-4 pt-4 pb-4">
        <Col xs={{ span: 10, offset: 1 }}>
          <StepsHeader currentStep={currentStep} steps={steps} />
          {
            currentStep === 1 ?
              <StepOne />
              :
              currentStep === 2 ?
                <StepTwo />
                :
                currentStep === 3 ?
                  <StepThree />
                  : null
          }
        </Col>
      </Row>

    </div>
  )
};


function mapStateToProps({ currentStep }) {
  return {
    currentStep,   
  }
}

// export default WizardForm;
export default connect(mapStateToProps)(WizardForm);