import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

const RegistrationCompleted = ({ orderRefNum }) => {

  const [ checkCompleted, setCompleted ] = useState("false");

  setTimeout(()=> setCompleted("true"), 1500);

  return (
    <Row>
      <Col xs={{ span: 12}} md={{ span: 10, offset: 1 }} className="registrion-success text-center p-md-5">

        <div className={`circle-loader ${checkCompleted === "true" ? "load-complete" : "" }`}>
          <div className={`checkmark draw ${checkCompleted  === "true" ? "checkMark-show" : "" }`}></div>
        </div>
        
        <h1 className="mt-3 mb-3">
          <span className="prime-color" style={{fontSize: '2rem'}}>Our mobile dispatch team has received your request and we will be contacting you shortly.</span><br />
          
        </h1>
        
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ orderRefNum }) => {
  return {
    orderRefNum
  }
}

export default connect(mapStateToProps)(RegistrationCompleted);