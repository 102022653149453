/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { createOrder, getFees, UPDATE_CURRENT_STEP, UPDATE_FORM_VALUES, verifyAccountNumber } from '../store/actions/form';
import { connect } from "react-redux";
import TextField from "./form-fields/TextField";
import { getFutureExpiryDate, COUNTRIES } from './../utils/helper';
import { SelectField } from './form-fields/SelectFieldAnt';
import { notification, Select, Tooltip } from 'antd';
import { useHistory } from 'react-router';
import { formInitialValues } from '../store/reducers';
import { scrollToTop } from '../utils/scroll';
import { PaymentType } from '../utils/enums';



const { currentYear, years, months, monthsPartial } = getFutureExpiryDate();
const { Option } = Select;


const StepThree = ({initialValues, apiData, isLoading, isAccountVerified, updateCurrentStep, updateStepValues, calcFees, submitOrder, verifyAccount}) => {
  const history = useHistory();
  const { watch, handleSubmit, getValues, setValue, setError, control} = useForm({defaultValues: initialValues, mode: 'all'});
  const [isVerified, setIsVerified] = useState(isAccountVerified);
  const paymentTab = watch('payment_type');


  const onError = message => {
    notification.error({
      message: 'Error',
      description: message || 'Something went wrong, Try again later',
      placement: 'topRight',
      duration: 5
    })
  }

  const onSuccessCallback = () => {
    updateStepValues(formInitialValues)
    scrollToTop('box-wrapper');
    history.push('/success');
    updateCurrentStep(1);
  }

  const onSubmit = data => {
    updateStepValues({ ...data});
    submitOrder(data, onError, onSuccessCallback, setError);
  }
  
  const onBack = _ => {
    const values = getValues();
    updateStepValues({ ...values});
    updateCurrentStep(2);
    scrollToTop('box-wrapper');
  }

  const isAccountNumberValid = () => {
    const accountNumber = getValues('account_number');
    return accountNumber && accountNumber.trim() !== '';
  }
  
  const handleVerifyAccount = () => {
    const accountNumber = getValues('account_number');
    verifyAccount(accountNumber, (error) => {
      if(!error) {
        setIsVerified(true);
        notification.success({
          message: 'Success',
          description: 'Account number verified successfully',
          placement: 'topRight',
          duration: 5
        });
      }
      else {
        setError('account_number', {
          type: 'manual',
          message: 'Invalid Account Number'
        });
      }
    }, setError);
  }

  const handleTabChange = (tab) => {
    
    setValue('payment_type',tab);
    
  }

  const expiryYearVal = watch('expiration_year');
  const accountNumberVal = watch('account_number');

  useEffect(() => {
    if (isVerified) {
      setIsVerified(false);
    }
  }, [accountNumberVal]);

  const yearsOptionsList = years?.map(year => 
    <Option key={year} value={year}>{year}</Option>
  );

  const monthsOptionsList = months?.map(month => 
    <Option key={month} value={month}>{month}</Option>
  );
  
  const monthsPartialOptionsList = monthsPartial?.map((month,i) => 
    <Option key={month} value={month}>{month}</Option>
  );

  const countriesOptionsList = COUNTRIES
        .sort((a,b) => a.name > b.name ? 1 : -1)
        .map(country => <Option key={country.name} value={country.code}>{country.name}</Option>)

  const numOfApplicant = initialValues.number_of_applicants;

  useEffect(() => {
    const { ori, authorized_applicant_type, billing_number, level_of_service, number_of_applicants } = initialValues;
    const data = { ori, authorized_applicant_type, billing_number, level_of_service, number_of_applicants };
    calcFees(data, onError);
    setIsVerified(isAccountVerified);
  }, []); //eslint-disable-line
  
  useEffect(() => {
    if(currentYear === +expiryYearVal) {
      setValue('expiration_month', `${monthsPartial[0]}`);
    }
  }, [setValue, expiryYearVal])

  const { 
    gov_fee,
    rolling_fee,
    service_fee,
    total
  } = apiData.fees

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">Payment Details</h3>
            </Col>
          </Row>
          
          <Row>
            <Col md="12" style={{marginBottom: "2rem"}}>
              <Row className="row fees-items text-left">
                <Col xs="10">
                  <h5>Service Fee</h5>
                </Col>
                <Col xs="2">
                  <h5>${paymentTab === PaymentType.ACCOUNT ? 0 : apiData.fees.service_fee}</h5>
                </Col>
              </Row> 
              <Row className="row fees-items text-left">
                <Col xs="10">
                  <h5>Government Fee <span style={{fontSize: '0.9rem'}}>{!!apiData.fees.gov_fee && `($${apiData.fees.gov_fee} per applicant)`}</span></h5>
                </Col>
                <Col xs="2">
                  <h5>${paymentTab === PaymentType.ACCOUNT ? 0 : apiData.fees.gov_fee * numOfApplicant}</h5>
                </Col>
              </Row> 
              <Row className="row fees-items text-left">
                <Col xs="10">
                  <h5>Rolling Fee <span style={{fontSize: '0.9rem'}}>{!!apiData.fees.rolling_fee && `($${apiData.fees.rolling_fee} per applicant)`}</span></h5>
                </Col>
                <Col xs="2">
                <h5>${paymentTab === PaymentType.ACCOUNT ? 0 : apiData.fees.rolling_fee * numOfApplicant}</h5>
                </Col>
              </Row>
              <Row className="row fees-heading text-left">
                <Col xs="10">
                  <h5>Total Fees *</h5>
                </Col>
                <Col xs="2">
                  <h5>${paymentTab === PaymentType.ACCOUNT ? 0 : apiData.fees.total}</h5>
                </Col>
              </Row>
              <Row className="row fees-items text-left">
              {paymentTab === PaymentType.CREDIT_CARD ? '* This is only an authorization, you will not be charged until after the event is completed.' :'* Transaction fees will be billed to your Certifix account and included in your monthly invoice.'}
              </Row> 
            </Col>
          </Row>

          <Row className="justify-content-center" style={{ marginBottom: '20px' }}>
            <Col md="auto" className="center-tabs">
              <button
                type="button"
                className={`payment-button credit-card ${paymentTab === PaymentType.CREDIT_CARD ? 'active-method' : ''}`}
                onClick={() => handleTabChange(1)}
                style={{ marginRight: '100px' }} 
              >
              </button>
              <button
                type="button"
                className={`payment-button charge-to-account ${paymentTab === PaymentType.ACCOUNT ? 'active-method' : ''}`}
                onClick={() => handleTabChange(2)}
              >
              </button>
            </Col>
          </Row>

          {paymentTab === PaymentType.CREDIT_CARD && (
            <>
              <Row className="form-row">
                <Col md="6">
                  <TextField
                    control={control} 
                    name="cardholder_name"
                    type="text"
                    rules={paymentTab === PaymentType.CREDIT_CARD ? {
                      required: 'Required',
                      maxLength: {
                        value: 50,
                        message: 'Max 50 characters.'
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9 ,.'\-]+$/,
                        message: 'En Letters, space, comma, period, single quote and hyphen (dash) allowed.'
                      } 
                    } : {}}
                    controlId="cardHolderNameStepFour"
                    label="Card Holder Name"
                  />
                </Col>
                <Col md="6">
                  <TextField
                    control={control} 
                    name="card_number"
                    type="tel"
                    inputMode="numeric"
                    autoComplete="cc-number"
                    formatCreditCard
                    rules={paymentTab === PaymentType.CREDIT_CARD ? {
                      required: 'Required',
                      pattern: {
                        value: /^[0-9 ]{18,19}$/,
                        message: 'A valid credit card is 15 ~ 16 digits.'
                      }} : {}}
                    controlId="cardNumberNameStepFour"
                    label="Card Number"
                    placeHolder="xxxx xxxx xxxx xxxx"
                  />
                </Col>
                <Col md="6">
                  <TextField
                    control={control} 
                    name="security_code"
                    type="tel"
                    inputMode="numeric"
                    autoComplete="cc-csc"
                    formatCreditCard
                    rules={paymentTab === PaymentType.CREDIT_CARD ? {
                      required: 'Required',
                      pattern: {
                        value: /^[0-9]{3,4}$/,
                        message: 'A valid security code is 3 ~ 4 digits.'
                      }} : {}}
                    controlId="cvcStepFour"
                    label="CVV"
                  />
                </Col>
                <Col md="6">
                  <Row className="form-row">
                    <Col md="12">
                      <label>
                        Expiry Date *
                      </label>
                    </Col>
                    <Col md="6">
                      <SelectField
                        name='expiration_month'
                        defaultValue={initialValues.expiration_month}
                        rules={paymentTab === PaymentType.CREDIT_CARD ? { required: 'Required' } : {}}
                        control={control}
                        onChangeCB={()=> {}}
                      >
                        <Option value='' disabled>
                          Expiry Month
                        </Option>
                        {+expiryYearVal === currentYear ? monthsPartialOptionsList : monthsOptionsList}
                      </SelectField>
                    </Col>
                    <Col md="6">
                      <SelectField
                        name='expiration_year'
                        defaultValue={initialValues.expiration_year}
                        rules={paymentTab === PaymentType.CREDIT_CARD ? { required: 'Required' } : {}}
                        control={control}
                        onChangeCB={()=> {}}
                      >
                        <Option value='' disabled>
                          Expiry Year
                        </Option>
                        {yearsOptionsList}
                      </SelectField>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h4 className="section-heading mt-4 mb-4">Billing Address</h4>
                </Col>
              </Row>
              <Row className="form-row">
                <Col md="6">
                  <TextField
                    controlId="billingStreetAddress"
                    label="Street Address"
                    autoFill="street-address"
                    type="text"
                    name="billing_street_address"
                    control={control}
                    rules={paymentTab === PaymentType.CREDIT_CARD ?{
                      required: 'Required',
                      maxLength: {
                        value: 50,
                        message: 'Max 50 characters.'
                      },
                      pattern: { 
                        value: /^[a-zA-Z0-9 !@#$&()\\\-`.,\/"']*$/i,
                        message: "English letter, numbers and !@#$&()\-`.,/\"'"
                      }}: {}}
                  />
                </Col>	
                <Col md="6">
                  <TextField
                    controlId="billingStreetAddress2"
                    label={`Street Address 2`}
                    optional={true}
                    type="text"
                    name="billing_street_address2"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 50,
                        message: 'Max 50 characters.'
                      },
                      pattern: { 
                        value: /^[a-zA-Z0-9 !@#$&()\\\-`.,\/"']*$/i,
                        message: "English letter, numbers and !@#$&()\-`.,/\"'"
                      }
                    }}
                  />
                </Col>
                <Col md="6">
                  <SelectField
                    controlId="billingCountry"
                    label="Country"
                    autoFill="country-name"
                    name="billing_country"
                    control={control}
                    rules={{required: 'Required'}}
                    defaultValue={initialValues.billing_country}
                    onChangeCB={()=> {}}
                  >
                    <Option value="" disabled></Option>
                    {countriesOptionsList}
                  </SelectField>						
                </Col>
                <Col md="6">
                  <TextField
                    controlId="billingCity"
                    label="City"
                    autoFill="address-level2"
                    optional={true}
                    type="text"
                    name="billing_city"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 50,
                        message: 'Max 50 characters.'
                      },
                      pattern: { 
                        value: /^[a-z -]*$/i, 
                        message: 'En Letters, space and hypen allowed.'
                      }
                    }}
                  />
                </Col>
                <Col md="6">
                  <TextField
                    controlId="billingState"
                    label="State"
                    autoFill="address-level1"
                    optional={true}
                    type="text"
                    name="billing_state"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 50,
                        message: 'Max 50 characters.'
                      },
                      pattern: { 
                        value: /^[a-z0-9 -]*$/i,
                        message: 'En Letters, space and hypen allowed.'
                      }
                    }}
                  />
                </Col>
                <Col md="6">
                  <TextField
                    controlId="billingZip"
                    label="Zip"
                    autoFill="postal-code"
                    type="text"
                    name="billing_zip"
                    rules={paymentTab === PaymentType.CREDIT_CARD ? {
                      required: 'Required',
                      pattern: {
                        value: /^\d{5}(?:[-]\d{4})?$/,
                        message: 'Invalid zip code.'
                      }
                    }: {}}
                    control={control}
                  />
                </Col>						
              </Row>
            </>
          )}

{paymentTab === PaymentType.ACCOUNT && (
  <>
    <Row>
    <label htmlFor="accountNumberStepThree" className="account-number-label">Account Number *</label>
        <Col md={{ span: 5, offset: 2 }} className="account-number-field">
        <TextField
          control={control}
          name="account_number"
          type="text"
          disabled={isVerified}
          rules={paymentTab === PaymentType.ACCOUNT ? { required: 'Required' } : {}}
          controlId="accountNumberStepThree"
        />
      </Col>
    </Row>

    <Row>
      <Col md="4" className="text-left" style={{marginLeft: '252px'}}>
        {isAccountVerified ? (
          <p className="account-verified-text">Account verified</p>
        ) : (
          <Button
            className="btn btn-primary"
            onClick={handleVerifyAccount}
            disabled={paymentTab !== PaymentType.ACCOUNT || !isAccountNumberValid()}
          >
            Verify
          </Button>
        )}
      </Col>
    </Row>
  </>
)}

          <Row className="mt-4 mb-3">
            <Col md="12">
              <input type="button" className="btn btn-outline-primary" value="Back" onClick={onBack} />
              <Button
                className="btn btn-primary" 
                disabled={isLoading || (paymentTab
                   === PaymentType.ACCOUNT && !isVerified)}
                style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}
                onClick={handleSubmit(onSubmit)}
              >
                <>
                {isLoading && 
                  <Spinner 
                    animation="border"
                    variant="light" 
                    size="sm" 
                    role="status"
                    aria-hidden="true"
                    style={{marginRight: '10px'}} 
                  />
                }
                  Submit
                </>
              </Button>
              
              {/* <input
                disabled={isLoading} 
                type="submit" className="btn btn-primary" value="Submit" /> */}
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({values, apiData, isLoading, isAccountVerified}) => {
  return {
    initialValues: values,
    apiData,
    isLoading,
    isAccountVerified
  }
}

const mapDispatchToProps = dispatch => {
  return {
    calcFees: (data, cb)=> dispatch(getFees(data, cb)),
    updateCurrentStep: (value)=> dispatch({type: UPDATE_CURRENT_STEP, value}),
    updateStepValues: (values)=> dispatch({type: UPDATE_FORM_VALUES, values}),
    submitOrder: (data, cb, cbSuccess, setError) => dispatch(createOrder(data, cb, cbSuccess, setError)),
    verifyAccount: (accountNumber, cb, setError) => dispatch(verifyAccountNumber(accountNumber, cb, setError))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);