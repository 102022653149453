/* eslint-disable no-useless-escape */
import { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { autoCompleteOri } from '../../store/actions/form';

const TextAsyncSearchField = ({
  control, 
  name, 
  rules, 
  errorMsgs, 
  controlId, 
  label,
  placeHolder,
  handleChange, 
  handleBlur,
  asyncData,
  defaultInputValue, 
  isLoading,
  onError,
  disabled,
}) => {
  
  const dispatch = useDispatch();

  const handleSearch = (query) => {
    const ONLY_EN_REGEX = /^[a-z0-9!"#$%&'()*+,.\/:;<=>?@\[\] ^_`{|}~-]*$/i
    const isEnglishValue = query.match(ONLY_EN_REGEX);
    if (!isEnglishValue) return;
    dispatch(autoCompleteOri(query, onError));
  };

  const filterBy = () => true;
  return (
    <Controller 
      render={({ 
        field,
        fieldState: { invalid, isTouched, isDirty, error } 
        })=> (
          <Form.Group controlId={controlId}>
            {label ?
              <Form.Label>{label} {rules?.required ? '*' : null}</Form.Label>
            : null
            }
            <AsyncTypeahead 
              {...field}
              isValid={isTouched && isDirty && !invalid}
              isInvalid={invalid}
              className={invalid ? 'is-invalid' : 'is-valid'}
              filterBy={filterBy}
              id={name}
              isLoading={isLoading}
              labelKey={name}
              minLength={3}
              onSearch={handleSearch}
              options={asyncData}
              placeholder={placeHolder}
              onChange={handleChange}
              onBlur={(e)=> {
                const eTargetValueUppercased = e?.target?.value?.toUpperCase();
                handleChange(eTargetValueUppercased)
              }}
              onInputChange={handleChange}
              defaultInputValue={defaultInputValue}
              renderMenuItemChildren={(option, props) => (
                <Fragment>
                  <span>{option.ori}</span>
                </Fragment>
              )}
              disabled={disabled??false}
            />
            <Form.Control.Feedback type="invalid">
              {error ?
                errorMsgs && error.type !== 'required' ?
                  errorMsgs[error.type] 
                :
                error.message ?
                  error.message :
                  'Required'
              : 
              null}  
            </Form.Control.Feedback>
          </Form.Group>    
        )}
      name={name}
      control={control ? control : null}
      rules={rules}
    />
    
  )
}

export default TextAsyncSearchField;