/* eslint-disable no-useless-escape */
import { useForm } from "react-hook-form";
import { Row, Col } from 'react-bootstrap';
import TextField from "./form-fields/TextField";
import 'antd/dist/antd.css';
import { updateCurrentStep, updateFormValues ,createLead} from '../store/actions/form';
import { connect } from "react-redux";
import { Select } from 'antd';
import { useEffect } from 'react';
import { stepsFields } from '../store/reducers';
import { scrollToTop } from '../utils/scroll';

const { Option } = Select;

const StepOne = ({
    initialValues,
    updateStepNumber,
    createLead,
    saveStepValues,
    globalErrors
  }) => {

  const { handleSubmit, control, setError } = useForm({ defaultValues: initialValues, mode: 'all' });

  const onSubmit = data => {
    createLead(data)
    saveStepValues(data);
    updateStepNumber(2);
    scrollToTop('box-wrapper');
  }

  useEffect(() => {
    globalErrors && Object.entries(globalErrors).forEach(([key, value]) =>
      stepsFields[0].includes(key) && setError(key, { type: value.type, message: value.message })
    )
  }, []) //eslint-disable-line

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="form-row">
            <Col md="6">
              <TextField
                control={control}
                name="first_name"
                type="text"
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 50,
                    message: 'Max 50 characters.'
                  },
                  pattern: {
                    value: /^[a-zA-Z ,.'-]+$/,
                    message: 'En Letters, space, comma, period, qoute, hypen allowed.'
                  }
                }}
                controlId="firstNameStepOne"
                label="First Name"
              />
            </Col>
            <Col md="6">
              <TextField
                control={control}
                name="last_name"
                type="text"
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 50,
                    message: 'Max 50 characters.'
                  },
                  pattern: {
                    value: /^[a-zA-Z ,.'-]+$/,
                    message: 'En Letters, space, comma, period, qoute and hypen allowed.'
                  }
                }}
                controlId="lastNameStepOne"
                label="Last Name"
              />
            </Col>
            <Col md="6">
              <TextField
                control={control}
                name="company"
                placeHolder="Optional"
                type="text"
                controlId="companyStepOne"
                label="Company"
                rules={{
                  maxLength: {
                    value: 50,
                    message: 'Max 50 characters.'
                  },
                  pattern: {
                    value: /^[a-z0-9!"#$%&'()*+,.\/:;<=>?@\[\] ^_`{|}~-]*$/i,
                    message: 'Invalid input'
                  }
                }}
              />
            </Col>
            <Col md="6">
              <TextField
                control={control}
                name="phone_number"
                type="text"
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                    message: 'Invalid phone number.'
                  }
                }}
                controlId="phoneStepOne"
                label="Phone"
              />
            </Col>
            <Col md="6">
              <TextField
                control={control}
                name="email"
                type="text"
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 100,
                    message: 'Max 100 characters.'
                  },
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Invalid email address.'
                  }
                }}
                controlId="emailStepOne"
                label="Email"
              />
            </Col>
          </Row>
          <Row className="mt-4 mb-3">
            <Col md="12">
              <input type="submit" className="btn btn-primary" value="Next" />
            </Col>
          </Row>

        </form>
      </Col>
    </Row>
  );
};

function mapStateToProps({ values, errors }) {
  return {
    initialValues: values,
    globalErrors: errors || {},
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateStepNumber: (value)=> dispatch(updateCurrentStep(value)),
    saveStepValues: (values)=> dispatch(updateFormValues(values)),
    createLead: (values)=> dispatch(createLead(values))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);