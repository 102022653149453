import axios from "axios";
import moment from 'moment';
import errorHandler from '../../services/fieldsErrorsHandler';
import { stepsFields } from "../reducers";
import { PaymentType } from '../../utils/enums';

export const SET_API_DATA = 'SET_API_DATA';
export const UPDATE_FORM_VALUES = 'UPDATE_FORM_VALUES';
export const UPDATE_FORM_ERRORS = 'UPDATE_FORM_ERRORS';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
export const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY';
export const FETCH_ORI_LIST_REQUEST = 'FETCH_ORI_LIST_REQUEST';
export const FETCH_ORI_LIST_SUCCESS = 'FETCH_ORI_LIST_SUCCESS';
export const FETCH_ORI_LIST_FAILURE = 'FETCH_ORI_LIST_FAILURE';
export const VERIFY_BILLING_NUMBER_REQUEST = 'VERIFY_BILLING_NUMBER_REQUEST';
export const VERIFY_BILLING_NUMBER_SUCCESS = 'VERIFY_BILLING_NUMBER_SUCCESS';
export const VERIFY_BILLING_NUMBER_FAILURE = 'VERIFY_BILLING_NUMBER_FAILURE';
export const GET_FEES_REQUEST = 'GET_FEES_REQUEST';
export const GET_FEES_SUCCESS = 'GET_FEES_SUCCESS';
export const GET_FEES_FAILURE = 'GET_FEES_FAILURE';
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';
export const AUTO_COMPLETE_ORI = 'AUTO_COMPLETE_ORI';
export const GET_APPLICANT_TYPE = 'GET_APPLICANT_TYPE';
export const FETCH_APPLICANT_TYPE_LIST_REQUEST = 'FETCH_APPLICANT_TYPE_LIST_REQUEST';
export const FETCH_APPLICANT_TYPE_LIST_SUCCESS = 'FETCH_APPLICANT_TYPE_LIST_SUCCESS';
export const FETCH_APPLICANT_TYPE_LIST_FAILURE = 'FETCH_APPLICANT_TYPE_LIST_FAILURE';
export const UPDATE_FBICA = 'UPDATE_FBICA';
export const UPDATE_LOS_OPTIONS = 'UPDATE_LOS_OPTIONS';
export const VERIFY_ACCOUNT_NUMBER_REQUEST = 'VERIFY_ACCOUNT_NUMBER_REQUEST';
export const VERIFY_ACCOUNT_NUMBER_SUCCESS = 'VERIFY_ACCOUNT_NUMBER_SUCCESS';
export const VERIFY_ACCOUNT_NUMBER_FAILURE = 'VERIFY_ACCOUNT_NUMBER_FAILURE';

export const API_BASE = process.env.REACT_APP_API_URL;

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'Accept': 'application/json'
};

export function updateFormValues (values) {
  return {
    type: UPDATE_FORM_VALUES,
    values
  }
}

export function updateFormErrors (errors) {
  return {
    type: UPDATE_FORM_ERRORS,
    errors
  }
}

export function updateCurrentStep (value) {
  return {
    type: UPDATE_CURRENT_STEP,
    value
  }
}

export function setApiData (data) {
  return {
    type: SET_API_DATA,
    data
  }
}

export function saveSearchQuery (query) {
  return {
    type: SAVE_SEARCH_QUERY,
    query
  }
}

export function setLocationsData (data) {
  return {
    type: UPDATE_LOCATIONS,
    data
  }
}

export const fetchOriListRequest = _ => {
  return {
    type: FETCH_ORI_LIST_REQUEST
  }
}

export const fetchOriListSuccess = data => {
  return {
    type: FETCH_ORI_LIST_SUCCESS,
    data
  }
}

export const fetchOriListFailure = error => {
  return {
    type: FETCH_ORI_LIST_FAILURE,
    error
  }
}

export const fetchOriAppTypesRequest = _ => {
  return {
    type: FETCH_APPLICANT_TYPE_LIST_REQUEST
  }
}

export const fetchOriAppTypesSuccess = data => {
  return {
    type: FETCH_APPLICANT_TYPE_LIST_SUCCESS,
    data
  }
}

export const fetchOriAppTypesFailure = error => {
  return {
    type: FETCH_APPLICANT_TYPE_LIST_FAILURE,
    error
  }
}

export const verifyBillingNumberRequest = _ => {
  return {
    type: VERIFY_BILLING_NUMBER_REQUEST
  }
}

export const verifyBillingNumberSuccess = data => {
  return {
    type: VERIFY_BILLING_NUMBER_SUCCESS,
    data
  }
}

export const verifyBillingNumberFailure = error => {
  return {
    type: VERIFY_BILLING_NUMBER_FAILURE,
    error
  }
}

export const getFeesRequest = _ => {
  return {
    type: GET_FEES_REQUEST
  }
}

export const getFeesSuccess = data => {
  return {
    type: GET_FEES_SUCCESS,
    data
  }
}

export const getFeesFailure = error => {
  return {
    type: GET_FEES_FAILURE,
    error
  }
}

export const createOrderRequest = _ => {
  return {
    type: CREATE_ORDER_REQUEST
  }
}

export const createOrderSuccess = data => {
  return {
    type: CREATE_ORDER_SUCCESS,
    data
  }
}

export const createOrderFailure = error => {
  return {
    type: CREATE_ORDER_FAILURE,
    error
  }
}

export const verifyAccountNumberRequest = () => {
  return {
    type: VERIFY_ACCOUNT_NUMBER_REQUEST
  }
}

export const verifyAccountNumberSuccess = () => {
  return {
    type: VERIFY_ACCOUNT_NUMBER_SUCCESS
  }
}

export const verifyAccountNumberFailure = error => {
  return {
    type: VERIFY_ACCOUNT_NUMBER_FAILURE,
    error
  }
}

export const autoCompleteOri = (ori, cb) => {
  return async dispatch => {
    dispatch(fetchOriListRequest());

    try {
      return axios.get(`${API_BASE}/api/v1/oris?query=${ori}`)
        .then(response => {
          if(response.status === 200) {
            const { data } =response.data.data;
            dispatch(fetchOriListSuccess(data))
          }
        })
        .catch(error => {
          cb(error.message);
        });
    } catch (error) {
      dispatch(fetchOriListFailure(error.message))
      cb();
    }
  }
}

export const getOriMatchingAppTypes = (ori, cb) => {
  return async dispatch => {
    dispatch(fetchOriAppTypesRequest());

    try {
      return axios.get(`${API_BASE}/api/v1/oris/${ori}/agencies`)
        .then(response => {
          if(response.status === 200) {
            const { data } =response.data;
            dispatch(fetchOriAppTypesSuccess(data))
          }
        })
        .catch(error => {
          cb(error.message);
        });
    } catch (error) {
      dispatch(fetchOriAppTypesFailure(error.message))
      cb();
    }
  }
}

export const verifyBillingNumWithOri = (billingNum, ori, cb) => {
  return async dispatch => {
    dispatch(fetchOriAppTypesRequest());

    try {
      return axios.head(`${API_BASE}/api/v1/online-enrollments/actions/check-billing-number-ori?applicant_billing_number=${billingNum}&ori=${ori}`)
        .then(response => {
          if(response.status === 200) {
            const { data } =response.data;
            dispatch(fetchOriAppTypesSuccess(data))
          } else {
            cb();
          }
        })
        .catch(error => {
          cb(error.message);
        });
    } catch (error) {
      dispatch(fetchOriAppTypesFailure(error.message))
      cb();
    }
  }
}

export const getFees = (data, cb) => {

  const { ori, authorized_applicant_type, level_of_service, billing_number, number_of_applicants } = data;

  return async dispatch => {
    dispatch(getFeesRequest());

    let level_of_service_doj = level_of_service.length && level_of_service.includes('DOJ') ? 1 : 0;
    let level_of_service_fbi = level_of_service.length && level_of_service.includes('FBI') ? 1 : 0;

    const params = new URLSearchParams()
    params.append('ori', ori)
    params.append('authorized_applicant_type', authorized_applicant_type)
    params.append('level_of_service_doj', level_of_service_doj)
    params.append('level_of_service_fbi', level_of_service_fbi)
    params.append('billing_number', billing_number)
    params.append('number_of_applicants', number_of_applicants)

    try {
      return axios.post(`${API_BASE}/api/v1/service-fees/actions/calculate`, params, { headers })
        .then(response => {
          if(response.status === 200) {
            const { data } =response.data;
            dispatch(getFeesSuccess(data))
          } else {
            cb();
          }
        })
        .catch(error => {
          cb(error.message);
        });
    } catch (error) {
      dispatch(getFeesFailure(error.message))
      cb();
    }
  }
}

export const createOrder = (data, cb, cbSuccess, rhfSetError) => {
  let level_of_service_doj;
  let level_of_service_fbi;

  let { 
    level_of_service,
    payment_type, 
    card_number,
    cardholder_name,
    security_code,
    expiration_month,
    expiration_year,
    account_number,
    address, city, state, zip_code,
    number_of_applicants,
    preferred_date_time,
    billing_street_address, billing_city, billing_state, billing_country, billing_zip,
    ...tempQuery } = data
  let preferredDateTime = moment().format('DD/MM/YYYY hh:mm a');

  level_of_service_doj = level_of_service.length && level_of_service.includes('DOJ') ? "1" : "0";
  level_of_service_fbi = level_of_service.length && level_of_service.includes('FBI') ? "1" : "0";

   // card_number = paytrace.encryptValue(card_number)
    // security_code = paytrace.encryptValue(security_code)
    // expiration_month = `${expiration_month}`;
    // expiration_year = `${expiration_year}`;

  // const billing_street_address = address;
  // const billing_country = "";
  // const billing_city = city;
  // const billing_state = state;
  // const billing_zip = zip_code;

  const billingInfo = { billing_street_address, billing_city, billing_state, billing_country, billing_zip }

  const querytoSend = {
    ...tempQuery, 
    number_of_applicants: +number_of_applicants, 
    preferred_date_time: preferredDateTime, 
    address, city, state, zip_code, 
    level_of_service_doj, level_of_service_fbi,
    payment_type
  };

  if (payment_type ===  PaymentType.CREDIT_CARD) { 
    querytoSend.cardholder_name = cardholder_name;
    querytoSend.card_number = card_number.replace(/\s/g, "");
    querytoSend.security_code = security_code;
    querytoSend.expiration_month = expiration_month;
    querytoSend.expiration_year = expiration_year;
    querytoSend.billing_street_address = billing_street_address;
    querytoSend.billing_country = billing_country;
    querytoSend.billing_city = billing_city;
    querytoSend.billing_state = billing_state;
    querytoSend.billing_zip = billing_zip;	
  } else { 
    querytoSend.account_number = account_number;
  }

  const urlEncodedQuery = new URLSearchParams(querytoSend).toString();
  
  return async dispatch => {
    dispatch(createOrderRequest());

    try {
      return axios.post(`${API_BASE}/api/v1/orders`, urlEncodedQuery, { headers })
        .then(response => {
          if(response.status === 200) {
            const { data } =response.data;
            dispatch(createOrderSuccess(data))
            cbSuccess();
          } else {
            cb();
          }
        })
        .catch(error => {
          dispatch(createOrderFailure(error.message))
          const errMessage = error?.response?.data?.errors[0]?.message
          const message = errMessage || error.message;
          error?.response?.status === 400 && errorHandler(error?.response?.data, rhfSetError, dispatch)
          cb(message);
        });
    } catch (error) {
      dispatch(createOrderFailure(error.message))
      cb();
    }
  }
}

export const verifyAccountNumber = (accountNumber, cb,  rhfSetError) => {
  return async dispatch => {
    dispatch(verifyAccountNumberRequest());
    const params = new URLSearchParams();
    params.append('account_number', accountNumber);
    try {
      return axios.post(`${API_BASE}/api/v1/cbs-accounts/actions/verify`, params, { headers })
        .then(response => {
          if (response.status === 200) {
            dispatch(verifyAccountNumberSuccess());
            cb();
          } else {
            cb();
          }
        })
        .catch(error => {
          dispatch(verifyAccountNumberFailure(error.message));
          const errMessage = error?.response?.data?.errors[0]?.message
          const message = errMessage || error.message;
          error?.response?.status === 400 && errorHandler(error?.response?.data, rhfSetError, dispatch)
          cb(message);
        });
    } catch (error) {
      dispatch(verifyAccountNumberFailure(error.message));
      cb();
    }
  }
}

export const updateFBICA = ({ 
  levelOfService, 
  LOSOptions, 
  LOSCheckedDisabled, 
  stateFBICA 
}) => dispatch => {
  dispatch({
    type: UPDATE_FBICA,
    payload: {
      levelOfService, 
      LOSOptions, 
      LOSCheckedDisabled, 
      stateFBICA
    }
  })
}

export const createLead = (data) => {


  const allKeys = Object.keys(data);
  const dataToBeSend = allKeys.reduce((next, key) => {
    if (stepsFields[0].includes(key)) {
      return { ...next, [key]: data[key] };
    } else {
      return next;
    }
  }, {});


  return async dispatch => {

    const urlEncodedQuery = new URLSearchParams(dataToBeSend).toString(); 
      return axios.post(`${API_BASE}/api/v1/orders/actions/create-lead`, urlEncodedQuery, { headers })       
  }
}