import { Controller } from 'react-hook-form'
import 'antd/dist/antd.css';
import { Select } from 'antd';

const SelectField = ({
  control,
  name,
  children,
  label,
  onChangeCB,
  defaultValue,
  rules,
  errorMsgs
}) => {
  return (
    <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ 
          field: { onChange, onBlur, value, ref },
          fieldState: { invalid, isTouched, isDirty, error } 
        }) => (
          <>
            {label && (<><label>{label} {rules ? '*' : null}</label><br /></>)}
            <Select
              className={error ? 'ant-invalid-select' : ''}
              label={label}
              defaultValue={defaultValue}
              onChange={(value)=> {
                onChange(value)
                onChangeCB()
              }}
              onBlur={(e)=> {
                onBlur()
                onChangeCB()
              }}
              value={value || defaultValue}
            >
              {children}
            </Select>
            {error ?
              <div className='ant-select-invalid-feedback' >
              {errorMsgs && error.type !== 'required' ?
                errorMsgs[error.type] 
              :
                'Required'
              }
              </div>
            : 
            null}
            
          </>
        )}
      />
  )
}

export { SelectField };