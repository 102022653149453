/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router';
import WizardForm from './components/WizardForm';
import RegistrationCompleted from './components/RegistrationCompleted';
import './acls.scss';
import './App.scss';
import { connect } from 'react-redux';

function App(props) {
  return (
    <div style={props}>
      <header id="masthead" className="site-header" >
        <div className="top-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-right">
                <nav id="top-navigation" className="top-navigation">
                  <div className="menu-top-menu-container">
                    <ul id="menu-top" className="menu">
                      <li id="menu-item-688" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-688"><a href="/" aria-current="page">Blog</a></li>
                      <li id="menu-item-683" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-683"><a href="https://www.certifixlivescan.com/frequently-asked-questions.html">FAQ</a></li>
                      <li id="menu-item-684" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-684"><a href="https://www.certifixlivescan.com/request-for-live-scan-forms.html">Forms</a></li>
                      <li id="menu-item-685" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-685"><a href="https://billing.certifixlivescan.com/">Login</a></li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="site-branding tttttttttttttttttttttttt">
                <a href="https://www.certifixlivescan.com" className="custom-logo-link" rel="home">
                  <img src="https://blog.certifixlivescan.com/wp-content/uploads/2019/04/CertifixLiveScanLogo.svg" className="custom-logo" alt="Certifix Live Scan" />
                </a>
              </div>
            </div>
            <div className="col-md-9">
              <nav id="site-navigation" className="main-navigation">
                <div className="menu-main-menu-container">
                  <ul id="primary-menu" className="menu">
                    <li id="menu-item-689" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-689"><a href="https://www.certifixlivescan.com/how-it-works.html">How it works</a></li>
                    <li id="menu-item-690" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-690"><a href="https://www.certifixlivescan.com/request-a-mobile-livescan-service.html">Mobile Services</a></li>
                    <li id="menu-item-691" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-691"><a href="https://www.certifixlivescan.com/open-a-live-scan-account.html">Corporate Billing</a></li>
                    <li id="menu-item-692" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-692"><a href="https://www.certifixlivescan.com/livescan-system.html">Live Scan System</a></li>
                    <li id="menu-item-693" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-693"><a href="https://www.certifixlivescan.com/certifix-livescan-locations.html">Get Fingerprinted</a></li>
                  </ul>
                </div>					
              </nav>
            </div>
          </div>
        </div>
      </header>
      <header id="mobile_navigation" className="">
        <div className="navigation-title">
            <span>Navigation</span>
        </div>

        <div className="menu-main-menu-container">
            <ul id="mobile-menu" className="menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-689"><a href="https://www.certifixlivescan.com/how-it-works.html">How it works</a></li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-690"><a href="https://www.certifixlivescan.com/request-a-mobile-livescan-service.html">Mobile Services</a></li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-691"><a href="https://www.certifixlivescan.com/open-a-live-scan-account.html">Corporate Billing</a></li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-692"><a href="https://www.certifixlivescan.com/livescan-system.html">Live Scan System</a></li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-693"><a href="https://www.certifixlivescan.com/certifix-livescan-locations.html">Get Fingerprinted</a></li>
            </ul>
        </div>
      </header>
      
      <div id="breadcrumbs" className="mb-5">
          <div className="container">
              <ol typeof="BreadcrumbList" vocab="http://schema.org/BreadcrumbList">
                  
                  <span property="itemListElement" typeof="ListItem"><a property="item" typeof="WebPage" title="Go to Certifix Live Scan." href="https://www.certifixlivescan.com" className="home"><span property="name">Home</span></a>
                  <meta property="position" content="1" />
                  </span><span property="itemListElement" typeof="ListItem"><span property="name">Request a Mobile Fingerprinting Service</span>
                  <meta property="position" content="2" />
                  </span>
              </ol>
          </div>
      </div>
      <Container className="App" as="main">
        <Switch>
          <Route
            exact
            path="/"
            render={(props)=> (
              <WizardForm { ...props } />
            )}
          />
          
          {
            props.orderRefNum
            ? <Route
                exact
                path="/success"
                render={(props)=> (
                  <RegistrationCompleted { ...props } />
                )}
              />
            : <Route
                exact
                path="*"
              >
                <Redirect to='/' />
              </Route>
          }

        </Switch>
        </Container>

      <div id="footer" className="mt-5">
        <div className="container">
            <div className="footer">
                <div className="row">
                    <div className="widget">
                        <h4>Contact</h4>
                        <ul className="contact-info">
                            <li><a id="telNum" href="tel:17147901929"> (800) 203-2057 </a></li>
                            <li><a href="mailto:support@certifixlivescan.com">support@certifixlivescan.com</a></li>
                            <li><a id="chatlink2" href="#">Chat With Us</a></li>
                        </ul>
                    </div>
                    <div className="widget">
                        <h4>Live Scan Fingerprinting</h4>
                        <div className="menu-footer-menu-1-container">
                            <ul id="menu-footer-menu-1" className="menu">
                                <li id="menu-item-17959" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17959"><a href="https://www.certifixlivescan.com/how-it-works.html">How It Works</a></li>
                                <li id="menu-item-17958" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17958"><a href="https://www.certifixlivescan.com/request-a-mobile-livescan-service.html">Mobile Services</a></li>
                                <li id="menu-item-30323" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-30323"><a href="https://www.certifixlivescan.com/live-scan-fingerprinting-classes.html">Live Scan Classes</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="widget">
                        <h4>For Business &amp; Employers</h4>
                        <div className="menu-footer-menu-2-container">
                            <ul id="menu-footer-menu-2" className="menu">
                                <li id="menu-item-17960" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17960"><a href="https://www.certifixlivescan.com/open-a-live-scan-account.html">Corporate Billing</a></li>
                                <li id="menu-item-30249" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-30249"><a href="https://www.certifixlivescan.com/join-our-network.html">Join Our Network</a></li>
                                <li id="menu-item-17961" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17961"><a href="https://www.certifixlivescan.com/livescan-system.html">Live Scan System</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="widget">
                        <h4>Company</h4>
                        <div className="menu-footer-menu-3-container">
                            <ul id="menu-footer-menu-3" className="menu">
                                <li id="menu-item-30500" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-30500"><a href="https://support.certifixlivescan.com/">Knowledge Base</a></li>
                                <li id="menu-item-17970" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17970"><a href="https://www.certifixlivescan.com/about-us.html">About Us</a></li>
                                <li id="menu-item-36225" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-36225"><a href="https://www.certifixlivescan.com/customer-reviews.html">Reviews</a></li>
                                <li id="menu-item-17969" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17969"><a href="https://www.certifixlivescan.com/frequently-asked-questions.html">FAQ</a></li>
                                <li id="menu-item-29957" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-29957"><a href="https://www.certifixlivescan.com/contact-us.html">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="text">
                    <script type="text/javascript">
                        document.write(new Date().getFullYear())
                    </script>2020 Certifix Inc. All Rights Reserved. <a href="https://www.certifixlivescan.com/privacy-policy.html">Privacy Policy</a> <a href="https://www.certifixlivescan.com/terms-of-use.html">Terms of Use</a></div>
                <div className="review-logos">
                    <a href="https://www.bbb.org/us/ca/anaheim/profile/live-scan-fingerprinting/certifix-live-scan-1126-172010560/#sealclick" rel="nofollow">
                      <img src="https://seal-central-northern-western-arizona.bbb.org/seals/blue-seal-200-42-whitetxt-bbb-172010560.png" style={{border: 0}} alt="Certifix Live Scan BBB Business Review" />
                    </a>
                    <a href="https://www.shopperapproved.com/reviews/certifixlivescan.com/">
                      <img src="//www.shopperapproved.com/newseals/28600/white-mini-icon.gif" data-src="//www.shopperapproved.com/newseals/28600/white-mini-icon.gif" />
                    </a>
                </div>
                <div className="social-icons">
                    <a title="Facebook" href="https://www.facebook.com/CertifixLiveScan/">
                        <svg className="icon" viewBox="0 0 24 24">
                            <use xlinkHref="#facebook">
                                <g id="facebook">
                                    <path d="M14.545 11.521l-1.74 0.002l0.052 6.648h-2.453l0.014-6.648H8.824V9.421h1.592l-0.001-1.236 c0-1.713 0.485-2.756 2.592-2.756h1.758V7.53h-1.098c-0.824 0-0.863 0.293-0.863 0.84l-0.004 1.051h1.975L14.545 11.521z"></path>
                                </g>
                            </use>
                        </svg>
                    </a>
                    <a title="Twitter" href="https://twitter.com/Certifix/">
                        <svg className="icon" viewBox="0 0 24 24">
                            <use xlinkHref="#twitter">
                                <g id="twitter">
                                    <path d="M18.614 6.604c-0.556 0.325-1.171 0.561-1.822 0.688c-0.526-0.551-1.271-0.896-2.099-0.896 c-1.586 0-2.875 1.269-2.875 2.83c0 0.2 0 0.4 0.1 0.646c-2.385-0.119-4.5-1.247-5.916-2.959 C5.729 7.3 5.6 7.8 5.6 8.336c0 1 0.5 1.9 1.3 2.354c-0.47-0.014-0.912-0.141-1.3-0.354c0 0 0 0 0 0 c0 1.4 1 2.5 2.3 2.774c-0.241 0.062-0.495 0.102-0.756 0.102c-0.186 0-0.365-0.02-0.541-0.055 c0.365 1.1 1.4 1.9 2.7 1.971c-0.982 0.756-2.222 1.208-3.567 1.208c-0.232 0-0.461-0.016-0.686-0.04 c1.271 0.8 2.8 1.3 4.4 1.272c5.286 0 8.171-4.312 8.171-8.055c0-0.123-0.003-0.246-0.009-0.367 C18.127 8.8 18.6 8.3 19 7.72c-0.516 0.225-1.068 0.378-1.648 0.446C17.943 7.8 18.4 7.3 18.6 6.604z"></path>
                                </g>
                            </use>
                        </svg>
                    </a>
                    <a title="Linkedin" href="https://www.linkedin.com/company/certifix-livescan/">
                        <svg className="icon" viewBox="0 0 24 24">
                            <use xlinkHref="#linkedin">
                                <g id="linkedin">
                                    <path d="M9.269 7.02c0 0.714-0.586 1.293-1.307 1.293c-0.722 0-1.307-0.579-1.307-1.293 c0-0.712 0.585-1.291 1.307-1.291C8.683 5.7 9.3 6.3 9.3 7.02H9.269z M9.061 9.279H6.873v7.392h2.188V9.279z M12.91 9.3 h-1.795l-0.027 7.392h2.044c0 0 0-2.742 0-3.879c0-1.04 0.775-1.79 1.7-1.665c0.824 0.1 1.1 0.6 1.1 1.7 c0 1.028-0.021 3.915-0.021 3.89h2.025c0 0 0.025-2.729 0.025-4.708c0-1.981-1.006-2.78-2.604-2.78 c-1.599 0-2.248 1.096-2.248 1.096v-1H12.91z"></path>
                                </g>
                            </use>
                        </svg>
                    </a>
                    <a title="YouTube" href="https://www.youtube.com/channel/UCilyNnqFdIl9yF7xm-We8aw/">
                        <svg className="icon" viewBox="0 0 24 24">
                            <use xlinkHref="#you-tube">
                                <g id="you-tube">
                                    <path d="M18.877 9.35c-0.22-1.924-0.96-2.189-2.438-2.292c-2.101-0.147-6.781-0.147-8.88 0C6.084 7.2 5.3 7.4 5.1 9.3 c-0.163 1.429-0.164 3.9 0 5.298c0.22 1.9 1 2.2 2.4 2.294c2.099 0.1 6.8 0.1 8.9 0 c1.477-0.104 2.217-0.369 2.437-2.294C19.041 13.2 19 10.8 18.9 9.35z M9.69 15.335v-6.65l5.623 3.324L9.69 15.335z"></path>
                                </g>
                            </use>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}


const mapStateToProps = ({ orderRefNum }) => ({
  orderRefNum,
});

export default connect(mapStateToProps)(App);