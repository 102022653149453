const StepsHeader = ({currentStep, steps}) => {
  return (
    <>
    <p className="text-center mt-4 mb-4">Step {currentStep} of {steps.length}</p>
    <ol className="c-progress-steps">
      {steps.map((stepTitle, index) => {
        return (
            <li 
              key={stepTitle}
              className={`c-progress-steps__step ${currentStep === index+1 ? "current" : ""} ${currentStep > index+1 ? "done" : ""}`}>
              <span>{stepTitle}</span>
            </li>
          );
        })
      }
    </ol>
    </>
  )
}

export default StepsHeader;