import { 
  UPDATE_FORM_VALUES, 
  UPDATE_FORM_ERRORS, 
  UPDATE_CURRENT_STEP,
  FETCH_ORI_LIST_SUCCESS,
  FETCH_APPLICANT_TYPE_LIST_SUCCESS,
  UPDATE_FBICA,
  UPDATE_LOS_OPTIONS,
  GET_FEES_SUCCESS,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_FAILURE,
  VERIFY_ACCOUNT_NUMBER_SUCCESS
} from '../actions/form';


export const stepsFields = [
  ['last_name','first_name','company','phone_number',
  'email','address','city', 'state', 'zip_code'],
  ['ori','authorized_applicant_type','billing_number','level_of_service','number_of_applicants','preferred_date_time','not_available_ori'],
  ['cardholder_name','card_number','security_code','expiration_month','expiration_year','account_number']
]

export const formInitialValues = {
  // Fill contact Information
  last_name: "",
  first_name: "",
  company: "",
  phone_number: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  
  // Fill Application Information
  ori: "",
  authorized_applicant_type: "",
  billing_number: "",
  level_of_service: [],
  number_of_applicants: "",
  preferred_date_time: "",
  not_available_ori : false,
  
  // Fill Payment Details
  payment_type: 1,
  cardholder_name: "",
  card_number: "",
  security_code: "",
  expiration_month: "",
  expiration_year: "",
  account_number: "",


  billing_street_address: "",
  billing_street_address2: "",
  billing_country: "US",
  billing_city: "",
  billing_state: "",
  billing_zip: "",
  
};

const initialState = {
  values: {
    ...formInitialValues
  },
  errors: {},
  apiData: {
    oriAutoComplete: [],
    oriAppTypes: [],
    levelOfService: ['DOJ', 'FBI'],
    fees: {
      gov_fee: 0,
      rolling_fee: 0,
      service_fee: 0,
      total: 0
    }
  },
  currentStep: 1,
  isLoading: false,
  levelOfService : 0, 
  LOSOptions: ["DOJ", "FBI"], 
  LOSCheckedDisabled: [], 
  stateFBICA: '',
  orderRefNum: '',
  isAccountVerified: false,
}

export default function form (state = initialState, action) {
  switch(action.type) {

    case CREATE_ORDER_REQUEST :
      return {
        ...state,
        isLoading: true
      }

    case CREATE_ORDER_FAILURE :
      return {
        ...state,
        isLoading: false
      }

    case UPDATE_CURRENT_STEP :
      return {
        ...state,
        currentStep: action.value
      }
    
    case UPDATE_FORM_VALUES :
      // debugger
      return {
        ...state,
        values: {
          ...state.values,
          ...action.values
        }
      }

    case UPDATE_FORM_ERRORS :
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.values
        }
      }

    case FETCH_ORI_LIST_SUCCESS :
      return {
        ...state,
        apiData: {
          ...state.apiData,
          oriAutoComplete: action.data
        }
      }

    case FETCH_APPLICANT_TYPE_LIST_SUCCESS :
      return {
        ...state,
        values: {
          ...state.values,
          level_of_service: []
        },
        apiData: {
          ...state.apiData,
          oriAppTypes: action.data
        },
        LOSOptions: ["DOJ", "FBI"],
        LOSCheckedDisabled: [],
        stateFBICA: '',
        levelOfService: 0
      }
    
    case UPDATE_FBICA:
      const { 
        levelOfService, 
        stateFBICA 
      } = action.payload

      let tempLOSCheckedDisabled = []
      let tempLOSOptions = []
      // Level Of Service Logic
      switch(levelOfService) {
        case "1":
          tempLOSCheckedDisabled = ["DOJ"]
          tempLOSOptions = ["DOJ"]
          break
        case "2":
          tempLOSCheckedDisabled = ["FBI"]
          tempLOSOptions = ["FBI"]
          break
        case "3":
          tempLOSCheckedDisabled = ["DOJ", "FBI"]
          tempLOSOptions = ["DOJ", "FBI"]
          break
        case "4":
          tempLOSCheckedDisabled = ["DOJ"]
          tempLOSOptions = ["DOJ", "FBI"]
          break
        default:
          tempLOSCheckedDisabled = []
          tempLOSOptions = ["DOJ", "FBI"]
      }


      return {
        ...state,
        values: {
          ...state.values,
          level_of_service: tempLOSCheckedDisabled
        },
        levelOfService, 
        LOSOptions: tempLOSOptions, 
        LOSCheckedDisabled: tempLOSCheckedDisabled, 
        stateFBICA
      
      }
    case UPDATE_LOS_OPTIONS:
      return {
        ...state,
        LOSOptions: action.payload.options,
        // LOSCheckedDisabled: action.payload.value
      }  
    case GET_FEES_SUCCESS:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          fees: {
            ...action.data
          }
        }
      }
    
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderRefNum: action?.data?.ref_number
      }

    case VERIFY_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        isAccountVerified: true
      }

    default :
      return state
  }
}