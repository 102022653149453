import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { UPDATE_CURRENT_STEP, UPDATE_FORM_VALUES, UPDATE_LOS_OPTIONS, getOriMatchingAppTypes, updateFBICA } from '../store/actions/form';
import RadioCheckboxField from "./form-fields/RadioCheckboxField";
import TextAsyncSearchField from './form-fields/TextAsyncSearchField';
import TextField from './form-fields/TextField';
import { notification, Space } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { SelectField as SelectAnt } from './form-fields/SelectFieldAnt';
import { Select } from 'antd';
import axios from 'axios';
import { stepsFields } from '../store/reducers';
import { scrollToTop } from '../utils/scroll';
import { US_STATES } from '../utils/helper';

const { Option } = Select;

const StepTwo = ({
  initialValues,
  apiData,
  isLoading,
  updateCurrentStep,
  updateStepValues,
  getMatchingApplicantTypes,
  updateLOSOptions,
  LOSOptions,
  LOSCheckedDisabled,
  stateFBICA,
  saveLOSOptions,
  globalErrors
}) => {
  let [, setCurrentFBICA] = useState(stateFBICA);
  const handleLOSChanged = (levelOfService, setValue) => {
    let tempLOSCheckedDisabled = []
    let tempLOSOptions = []
    // Level Of Service Logic
    switch (levelOfService) {
      case "1":
        tempLOSCheckedDisabled = ["DOJ"]
        tempLOSOptions = ["DOJ"]
        break
      case "2":
        tempLOSCheckedDisabled = ["FBI"]
        tempLOSOptions = ["FBI"]
        break
      case "3":
        tempLOSCheckedDisabled = ["DOJ", "FBI"]
        tempLOSOptions = ["DOJ", "FBI"]
        break
      case "4":
        tempLOSCheckedDisabled = ["DOJ"]
        tempLOSOptions = ["DOJ", "FBI"]
        break
      default:
        tempLOSCheckedDisabled = []
        tempLOSOptions = ["DOJ", "FBI"]
    }
    setValue('level_of_service', tempLOSCheckedDisabled)
    updateLOSOptions({ levelOfService, LOSOptions: tempLOSOptions, LOSCheckedDisabled: tempLOSCheckedDisabled, stateFBICA: levelOfService })
  }

  const { handleSubmit, getValues, setError, setValue, control, resetField } = useForm({ defaultValues: { ...initialValues }, mode: 'all' });

  const [notAvailableOri, setNotAvailableOri] = useState(initialValues.not_available_ori);
  useEffect(() => {
    setValue('not_available_ori', notAvailableOri)
    if (notAvailableOri) {
      setValue('ori', '')
      handleOriChanged('');
      handleLOSChanged(0, setValue)

    }
  }, [notAvailableOri])
  const onError = errors => {
    if (errors.dateOfBirth && errors.dateOfBirth.type === 'required') {
      setError('dateOfBirth', { type: 'required', message: 'Required' }, { shouldFocus: true });
    }
  }

  // Notification for API Errors
  const onErrorCB = message => {
    notification.error({
      message: 'Error',
      description: message || 'Something went wrong, Try again later',
      placement: 'topRight',
      duration: 5
    })
  }

  const handleOriChanged = value => {

    setValue('authorized_applicant_type', '')
    setValue('billing_number', '')
    let oriValue = typeof value === 'string' ? value : value.length ? value[0].ori : '';
    oriValue = oriValue.toUpperCase();
    setValue('ori', oriValue)
    if (oriValue.length >= 5) {
      getMatchingApplicantTypes(oriValue, onErrorCB);

      resetField('authorized_applicant_type', {
        value: '',
        defaultValue: ''
      })
      resetField('level_of_service', {
        defaultValue: [],
      })
    }
  }

  const appTypes = apiData.oriAppTypes.map((appType) =>
    <Option key={appType.auth_applicant_type} value={appType.auth_applicant_type}>{appType.auth_applicant_type}</Option>
  );

  const states = US_STATES.map((state) =>
    <Option key={state.abbreviation} value={state.abbreviation}>{state.name}</Option>
  );

  const getFBICA = (oriVal, applicantTypeVal, apiRes, setValue) => {
    if (oriVal && applicantTypeVal && apiRes) {
      const matched = apiRes.filter(data => data.ori === oriVal && data.auth_applicant_type === applicantTypeVal)
      if (matched[0]) {
        setCurrentFBICA(matched[0].fb_ca)
        handleLOSChanged(matched[0].fb_ca, setValue)
      }
    }
  }

  const disabledMinutesFunc = () => {
    const minutes = Array.from({ length: 60 }, (x, i) => i);
    return minutes.filter(i => [0, 15, 30, 45].indexOf(i) === -1)
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }



  useEffect(() => {
    globalErrors && Object.entries(globalErrors).forEach(([key, value]) =>
      stepsFields[1].includes(key) && setError(key, { type: value.type, message: value.message })
    )
  }, []) //eslint-disable-line

  const onSubmit = data => {

    const onSuccess = _ => {
      updateStepValues(data);
      updateCurrentStep(3);
      scrollToTop('box-wrapper');
    }

    const nextStepHandler = async (data, setError, onSuccessCB) => {
      const billingNum = data.billing_number
      const ori = data.ori
      const API_BASE = process.env.REACT_APP_API_URL;

      if (billingNum.length) {
        try {
          return axios.head(`${API_BASE}/api/v1/online-enrollments/actions/check-billing-number-ori?applicant_billing_number=${billingNum}&ori=${ori}`)
            .then(response => {
              if (response.status === 200) {
                onSuccessCB();
              }
            }).catch(error => {
              if (error.response.status === 400) {
                setError('billing_number', { type: 'manual', message: 'Billing number provided does not match this agency\'s ORI.' }, { shouldFocus: true });
              } else if (error.response.status === 404) {
                setError('billing_number', { type: 'manual', message: 'Billing number not found' }, { shouldFocus: true });
              } else {
                onSuccessCB();
              }
            })
        } catch (error) {
          onErrorCB()
        }

      } else {
        onSuccessCB();
      }
    }

    nextStepHandler(data, setError, onSuccess);
  }

  const onBack = _ => {
    const values = getValues();
    updateStepValues(values);
    updateCurrentStep(1);
    scrollToTop('box-wrapper');
  }

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">Applicant Info</h3>
            </Col>
          </Row>
          <Row className="form-row">
            <Col md="6" className="search-input-row">
              { notAvailableOri? 
              (<TextField 
              control={control}
              name="ori" type="text" label="ORI"
              rules={{required: false}} 
              disabled={true}/>)
               : 
              (<TextAsyncSearchField
                control={control}
                name="ori"
                label="ORI"
                defaultInputValue={initialValues.ori}
                handleChange={handleOriChanged}
                onKeyDown={(e) => {
                  const eTargetValueUppercased = e?.target?.value?.toUpperCase();
                  handleOriChanged(eTargetValueUppercased)
                }}
                disabled={notAvailableOri}
                asyncData={apiData.oriAutoComplete}
                isLoading={isLoading}
                rules={{
                  required: !notAvailableOri && 'Required',
                  minLength: {
                    value: 5,
                    message: 'Min 5 Characters'
                  },
                  maxLength: {
                    value: 9,
                    message: 'Max 9 Characters'
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9]*$/i,
                    message: '5 - 9 Alphanumeric field.'
                  }
                }}
                onError={onErrorCB}
              />
              )}
              <input checked={notAvailableOri}
                onChange={e => setNotAvailableOri(e.target.checked)}
                className="mr-2 mb-3"
                name="not_available_ori"
                type="checkbox" />
              <small> <b>I do not have an ORI number or I need physical fingerprint cards.</b> </small>
            </Col>
            {!notAvailableOri && <>
              <Col md="6">
                <SelectAnt
                  name='authorized_applicant_type'
                  defaultValue={initialValues.authorized_applicant_type}
                  rules={{ required: 'Required' }}
                  label="Applicant Type"
                  control={control}
                  onChangeCB={() => {
                    getFBICA(getValues('ori'), getValues('authorized_applicant_type'), apiData.oriAppTypes, setValue)
                  }}
                >
                  <Option value='' disabled>
                    Select option
                  </Option>
                  {appTypes}
                </SelectAnt>
              </Col>

              <Col md="6">
                <TextField
                  control={control}
                  name="billing_number"
                  placeHolder="Optional"
                  type="text"
                  controlId="billingNumberStepOne"
                  label="Billing Number"
                  rules={{
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Only numbers allowed.'
                    }
                  }}
                />
              </Col>

              <Col md="6">
                <RadioCheckboxField
                  control={control}
                  name="level_of_service"
                  rules={{ required: 'Required' }}
                  controlId="levelOfServiceStepTwo"
                  label="Level of Service"
                  type="checkbox"
                  options={LOSOptions}
                  setValue={setValue}
                  defaultValue={initialValues.level_of_service}
                  checkedDisabled={LOSCheckedDisabled}
                  handleChange={(value) => {
                    saveLOSOptions(value, LOSOptions)
                  }}

                />
              </Col>
            </>}
            <Col md="6">
              <TextField
                control={control}
                name="number_of_applicants"
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^(?:[1-2][0-9]{0,2}|[3-9][0-9]{0,1}|300)$/,
                    message: 'Numbers from 1 - 300 allowed'
                  }
                }}
                controlId="numOfApplicantsTwo"
                label="Estimated Number of Applicants"
                type='number'
                min='1'
                max='300'
                step='1'
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">Event Address</h3>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <TextField
                control={control}
                name="address"
                type="text"
                rules={{
                  maxLength: {
                    value: 50,
                    message: 'Max 50 characters.'
                  },
                  required: 'Required',
                  pattern: {
                    value: /^[a-zA-Z0-9 !@#$&()\\\-`.,\/"']*$/i,
                    message: "English letter, numbers and !@#$&()\-`.,/\"'"
                  }
                }}
                controlId="addressStepOne"
                label="Street Address"
              />
            </Col>
            <Col md="6">
              <TextField
                control={control}
                name="city"
                type="text"
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 50,
                    message: 'Max 50 characters.'
                  },
                  pattern: {
                    value: /^[a-zA-Z -]+$/,
                    message: 'En Letters, space and hypen allowed.'
                  }
                }}
                controlId="cityStepOne"
                label="City"
              />
            </Col>
            <Col md="6">
              <SelectAnt
                name='state'
                defaultValue={initialValues.authorized_applicant_type}
                rules={{ required: 'Required' }}
                label="State"
                control={control}
                onChangeCB={() => {

                }}
              >
                <Option value='' disabled>
                  Select option
                </Option>
                {states}
              </SelectAnt>

            </Col>
            <Col md="6">
              <TextField
                control={control}
                name="zip_code"
                type="text"
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^\d{5}(?:[-]\d{4})?$/,
                    message: 'Invalid zip code.'
                  }
                }}
                controlId="zipCodeStepOne"
                label="Zip Code"
              />
            </Col>
          </Row>
          <Row className="mt-4 mb-3">
            <Col md="12">
              <input type="button" className="btn btn-outline-primary" value="Back" onClick={onBack} />
              <input type="submit" className="btn btn-primary" value="Next" />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

function mapStateToProps({
  values,
  apiData,
  isLoading,
  levelOfService,
  LOSOptions,
  LOSCheckedDisabled,
  stateFBICA,
  errors
}) {
  return {
    initialValues: values,
    apiData,
    isLoading,
    levelOfService,
    LOSOptions,
    LOSCheckedDisabled,
    stateFBICA,
    globalErrors: errors || {},
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentStep: (value) => dispatch({ type: UPDATE_CURRENT_STEP, value }),
    updateStepValues: (values) => dispatch({ type: UPDATE_FORM_VALUES, values }),
    getMatchingApplicantTypes: (ori, cb) => dispatch(getOriMatchingAppTypes(ori, cb)),
    saveLOSOptions: (value, options) => dispatch({ type: UPDATE_LOS_OPTIONS, payload: { options, value } }),
    updateLOSOptions: ({ levelOfService, LOSOptions, LOSCheckedDisabled, stateFBICA }) => dispatch(updateFBICA({ levelOfService, LOSOptions, LOSCheckedDisabled, stateFBICA }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);